import { PageProps } from "gatsby";
import React from "react";
import Layout from "../components/parts/Layout";
import { snProps } from "../js/utils";

const NotFoundPage = (props: PageProps) => (
  <Layout
    {...{ ...snProps, ...props }}
    title="404: Not Found"
    description="This page does not exist."
  >
    <h1>404: NOT FOUND</h1>
    <p>This page does not exist.</p>
  </Layout>
);

export default NotFoundPage;
